<template>
  <v-sheet
    class="hourly-heatmap d-flex flex-column"
    :height="height"
  >
    <v-container 
      v-if="data!=null||animation.data!=null"
      fluid 
      class="px-0 flex-grow-1 d-flex flex-column"
    >
      <v-row dense class="flex-grow-0">
        <v-col
          v-for="day in hAxis"
          :key="day"
          class="hAxis axis text-center"
        >
          <span class="caption text-capitalize">
            {{ $moment().day(day).format('ddd') }}
          </span>
        </v-col>
        <v-col cols="2" style="max-width: 40px !important;">
          <!-- empty -->
        </v-col>
      </v-row>
      <v-row dense class="flex-grow-1">
        <v-col class="d-flex flex-column">
          <v-row
            v-for="(h, i) in vAxis"
            :key="i"
            no-gutters
            class="row flex-grow-1"
          >
            <v-col
              v-for="(d, j) in hAxis"
              :key="j"
              class="col"
              style="padding: 2px;"
            >
              <v-menu 
                open-on-hover
                bottom
                offset-x
                nudge-right="8"
                nudge-top="12"
                :close-on-content-click="false"
                open-delay="200"
                transition="slide-x-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="tile fill-height"
                    :style="[hour[i][j].intensity]"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-card class="px-4 py-2">
                  <h3 class="title subtitle-2 text-capitalize">
                    {{ $moment().day(d).format('ddd') + ', ' + h }}
                  </h3>
                  <h4 class="value display-1 font-weight-light mt-2">
                    {{ $numeral(enableTooltip ? hour[i][j].data : null).format(format) }}
                  </h4>
                  <span class="caption">
                    {{ label }}
                  </span>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col 
          cols="2"
          class="pr-2 d-flex flex-column"
          style="max-width: 40px !important;"
        >
          <v-row
            v-for="h in vAxis"
            :key="h"
            no-gutters
            class="vAxis axis flex-grow-1"
          >
            <v-col class="d-flex align-center justify-right">
              <span 
                class="caption d-block"
                :class="{ 'visible': toggleLabel(h) }"
              >
                {{ h }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container 
      v-if="data!=null"
      fluid 
      class="legend pa-0 pb-2"
    >
      <v-row no-gutters>
        <v-col
          v-for="(step, k) in this.legend.steps"
          :key="'legend-tile-'+k"
          class="step"
          align-self="center"
          style="padding: 2px"
        >
          <div
            class="tile"
            :style="[calcIntensity(step), { 'height': (tile/2)+'px' }]"
          />
        </v-col>
        <v-col cols="2" style="max-width: 40px !important;">
          <!-- empty -->
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          v-for="(step, k) in this.legend.steps"
          :key="'legend-number-'+k"
          class="step text-center"
          style="padding: 2px"
        >
          <span 
            v-if="k!=1&&k!=3"
            class="caption"
          >
            {{ $numeral(step).format(format) }}
          </span>
        </v-col>
        <v-col cols="2" style="max-width: 40px !important;">
          <!-- empty -->
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<style>

  .hourly-heatmap .caption {
    color: #666;
  }

  .hourly-heatmap .tile {
    position: relative;
    will-change: box-shadow, background-color;
    transition: box-shadow .25s ease, background-color .75s linear;
    box-shadow: 0 0 0 0 white ;
    cursor: pointer;
  }
  .hourly-heatmap .tile:hover {
    z-index: 200;
    box-shadow: 0 0 8px 6px white ;
  }
    
  .hourly-heatmap .vAxis .caption {
    display: block;
    line-height: 12px;
    visibility: hidden;
  }
  .hourly-heatmap .vAxis .caption.visible {
    visibility: visible;
  }

  .hourly-heatmap .step .caption {
    display: inline-block;
    line-height: 12px;
  }


</style>

<script>
  import { mdiHelpCircleOutline } from '@mdi/js';
  import services from '@/services';

  export default {

    props: {
      data: {
        type: Array,
        default: () => {
          return null;
        }
      },
      hAxis: {
        type: Array,
        default: () => {
          return [...Array(7).keys()]
        }
      },
      vAxis: {
        type: Array,
        default: () => {
          return _.map([...Array(24).keys()], (h) => {
            return h + 'h';
          });
        }
      },
      tile: {
        type: Number,
        default: 12
      },
      height: {
        type: Number,
        default: undefined
      },
      label: {
        type: String,
        default: ''
      },
      format: {
        type: String,
        default: '0[,]0[.]0 a'
      },
      inverted: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      icons: {
        help: mdiHelpCircleOutline,
      },
      min: 0,
      max: 0,
      delta: 0,
      legend: {
        steps: [],
        range: 3
      },
      animation: {
        toggle: false,
        data: null,
        interval: 500,
        controller: null,
      }
    }),

    watch: {
      data: {
        immediate: true,
        deep: true,
        handler (source) {
          this.toggleLoading(_.isNil(source));

          source = _.flatten(source);
          this.min = _.min(source);
          this.min = this.min == 0 ? 1 : this.min;
          this.max = _.max(source);
          this.delta = this.max - this.min;
          // console.log('min/max', this.min, this.max);
          this.legend.steps = _.concat([this.min], _.map([...Array(this.legend.range).keys()], (r) => {
            return this.min + ((this.delta / (this.legend.range+2)) * (r+1));
          }), [this.max]);
        }
      }
    },

    computed: {
      hour () {
        const data = this.data==null ? this.animation.data : this.data;
        return _.map([...Array(24).keys()], (h) => {
          return _.map([...Array(7).keys()], (d) => {
            const v = data[h][d];
            const max = this.max;
            const r = v>0 ? (v/max) : 0;
            const i = this.inverted ? (1 - (r * .92)) : ((r * .92) + .08);
            return {
              data: v,
              intensity: { 'background-color': this.data!=null ? r != 0 ? 'rgba(71, 108, 255, ' + i.toString() + ')' : 'rgba(38,39,44,.08)' : 'rgba(38,39,44,' + (i * .08).toString() + ')'
              }
            }
          });
        });
      },

      enableTooltip () {
        return this.data!=null;
      }
    },

    methods: {
      ...services,

      toggleLoading (toggle) {
        if (toggle) {
          this.animation.toggle = toggle;
          // console.log('loading animation start');
          if (this.animation.controller==null) this.animation.controller = setTimeout(this.animateLoading, this.animation.interval, 0);
        }else{
          clearTimeout(this.animation.controller);
          this.animation.controller = null;
          this.animation.toggle = toggle;
        }
      },

      animateLoading (progress, up) {
        if (!this.animation.toggle) return null;
        // const slots = this.length;
        // const velocity = slots/30;
        const amplitude = 24;
        const frequency = 4;
        const peak = 24 * (progress/100);

        // this.animation.data = _.map([...Array(24).keys()], (h) => {
        //   return _.map([...Array(7).keys()], (d) => {
        //     return this.random(25, 100);
        //   });
        // });
        this.animation.data = _.map([...Array(24).keys()], (h) => {
          return _.map([...Array(7).keys()], (d) => {
            let v = (amplitude * Math.sin((h-(peak-d))/frequency)) + (amplitude/2);
            v = v < 0 ? 2 : v;
            return v;
          });
        });
        const data = _.flatten(this.animation.data);
        const min = _.min(data);
        const max = _.max(data);
        this.delta = max - min;
        
        // console.log('loading animation update', progress, data.join(','));
        const next = progress + (24); 
        this.animation.controller = setTimeout(this.animateLoading, this.animation.interval, next);
      },

      calcIntensity (v) {
        const max = this.max;
        const r = v>0 ? (v/max) : 0;
        const i = this.inverted ? (1 - (r * .92)) : ((r * .92) + .08);
        return {
          'background-color': this.data!=null ? r != 0 ? 'rgba(71, 108, 255, ' + i.toString() + ')' : 'rgba(38,39,44,.08)' : 'rgba(38,39,44,' + (i * .08).toString() + ')'
        };
      },
      toggleLabel (v) {
        return parseInt(v) % 2 == 0;
      }
    },
    beforeDestroy () {
      this.animation.controller = clearTimeout(this.animation.controller);
      this.animation.data = null;
    }
  }
</script>